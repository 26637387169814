import { useState } from 'react';
import './collapsible.scss'

export default function Collapsible(props: {
    title: string;
    children: any;
}) {
    const { title, children } = props;
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="certified-collapsible">
            <div 
            className="collapsible-title"
            onClick={() => setIsOpen(!isOpen)}
            >   
                <div className="collapsible-title-text">
                    {title}
                </div>
                <div className="collapsible-title-icon">
                    {isOpen ? '▲' : '▼'}
                </div>
            </div>
            {isOpen && (
                <div className="collapsible-content">
                    {children}
                </div>
            )}
        </div>
    )
}