import Collapsible from "../../Collapsible/Collapsible";
import { ArrayFieldInput } from "../../FormFields/ArrayField/ArrayFieldInput";
import CheckboxInputField from "../../FormFields/CheckboxInputField/CheckboxInputField";
import RadioInputField from "../../FormFields/RadioInputField/RadioInputField";
import SelectInputField from "../../FormFields/SelectInputField/SelectInputField";
import TextInputField from "../../FormFields/TextInputField/TextInputField";

export const BOIReportFields = (props: {
    errors: any;
    touched: any;
    values: any;
    setFieldValue: (field: string, value: any) => void;
}) => {
    const { values, errors, touched, setFieldValue } = props;
    console.log(values);
    return (
        <>
            <Collapsible
                title="PRIVACY ACT AND PAPERWORK REDUCTION ACT NOTICE (click to read more)"
            >
                This notice is given under the Privacy Act of 1974 (Privacy Act) and the Paperwork Reduction Act of 1995 (Paperwork Reduction Act). The Privacy Act and Paperwork Reduction Act require that FinCEN inform persons of the following when requesting and collecting information in connection with this collection of information.
                <br /><br />
                This collection of information is authorized under 31 U.S.C. 5336 and 31 C.F.R. 1010.380. The principal purpose of this collection of information is to generate a database of information that is highly useful in facilitating national security, intelligence, and law enforcement activities, as well as compliance with anti-money laundering, countering the financing of terrorism, and customer due diligence requirements under applicable law. Pursuant to 31 U.S.C. 5336 and 31 C.F.R. 1010.380, reporting companies and certain other persons must provide specified information. The provision of that information is mandatory and failure to provide that information may result in criminal and civil penalties. The provision of information for the purpose of requesting a FinCEN Identifier is voluntary; however, failure to provide such information may result in the denial of such a request.
                <br /><br />
                Generally, the information within this collection of information may be shared as a “routine use” with other government agencies and financial institutions that meet certain criteria under applicable law. The complete list of routine uses of the information is set forth in the relevant Privacy Act system of record notice available at
                <br />
                <a
                    href="https://www.federalregister.gov/documents/2023/09/13/2023-19814/privacy-act-of-1974-system-of-records"
                    target="_blank"
                    className="link-text"
                    rel="noreferrer"
                >
                    https://www.federalregister.gov/documents/2023/09/13/2023-19814/privacy-act-of-1974-system-of-records
                </a>
                <br /><br />
                According to the Paperwork Reduction Act of 1995, no persons are required to respond to a collection of information unless it displays a valid OMB control number. The valid OMB control number for this information collection is 1506-0076. It expires on November 30, 2026.
                <br /><br />
                The estimated average burden associated with this collection of information from reporting companies is 90 to 650 minutes per respondent for reporting companies with simple or complex beneficial ownership structures, respectively. The estimated average burden associated with reporting companies updating information previously provided is 40 to 170 minutes per respondent for reporting companies with simple or complex beneficial ownership structures, respectively. The estimated average burden associated with this collection of information from individuals applying for FinCEN identifiers is 20 minutes per applicant. The estimated average burden associated with individuals who have obtained FinCEN identifiers updating information previously provided is 10 minutes per individual. Comments regarding the accuracy of this burden estimate, and suggestions for reducing the burden should be directed to the Financial Crimes Enforcement Network, P. O. Box 39, Vienna, VA 22183, Attn: Policy Division.
            </Collapsible>

            <Collapsible
                title="Need Help? (click to read more)"
            >
                <b>
                    Instructions for Item 1 - Type of filing:
                </b>
                <br />
                <ul>
                    <li>
                        Check box 1a “Initial report” if this is the first BOIR filed for the reporting company.
                    </li>

                    <li>
                        Check box 1b “Correct prior report” if the report corrects inaccurate information from a previously filed BOIR.
                    </li>

                    <li>
                        Check box 1c “Update prior report” if the report updates a previously filed BOIR, for example, to include one or more new beneficial owners.
                    </li>

                    <li>
                        Check box 1d “Newly exempt entity” if, after having filed a BOIR, the reporting company is now exempt from BOI reporting requirements. If this checkbox is selected, then filer must fill out fields 1e–1h (reporting company information associated with most recent report) and no other fields in the BOIR.
                    </li>

                    <li>
                        If type of filing 1b, 1c, or 1d is selected, enter values for 1e “Legal name”, 1f “Tax identification type”, and 1g “Tax identification number” for the reporting company that match the most recently filed BOIR. For example, if the reporting company previously filed an initial report and then an updated report, the reporting company information in the next updated report should match the reporting company information provided on the most recently filed updated report rather than the initial report.
                    </li>

                    <li>
                        For 1f, select the tax identification type (i.e., a U.S. Employer Identification Number (EIN), a U.S. Social Security Number or Individual Taxpayer Identification Number (SSN-ITIN), or foreign taxpayer identification number (Foreign)) the reporting company reported in the most recently filed BOIR. If tax identification type entered in 1f is “Foreign”, select the corresponding foreign country/jurisdiction in 1h “Country/Jurisdiction (if foreign tax ID only)” associated with the foreign tax identification number.
                    </li>
                </ul>
                <b>
                    Instructions for Item 2 - Date prepared:
                </b>
                <br />
                This item is automatically populated with the current date.
            </Collapsible>


            <RadioInputField
                fieldName={"boiReport.typeOfFiling"}
                fieldLabel={`1. Type of Filing`}
                errors={errors}
                touched={touched}
                required={true}
                customClass={"block-radio"}
                options={[
                    { value: 'initial report', label: 'a. Initial Report' },
                    { value: 'correct prior report', label: 'b. Correct prior report' },
                    { value: 'update prior report', label: 'c. Update prior report' },
                    { value: 'newly exempt entity', label: 'd. Newly exempt entity' },
                ]}
            />

            {values.boiReport && values.boiReport.typeOfFiling && values.boiReport.typeOfFiling !== 'initial report' && (
                <>

                    <h3>
                        Reporting Company associated with latest report (not applicable for initial report)
                    </h3>

                    <TextInputField
                        fieldName={"boiReport.legaBusinessName"}
                        fieldLabel={`e. Legal business name`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    {/* select input for taxIdType options are: ein, ssnItin, foreign */}
                    <SelectInputField
                        fieldName={"boiReport.taxIdType"}
                        fieldLabel={`f. Tax Identification type`}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={"- Select an ID Type -"}
                        options={[
                            { value: 'ein', label: 'EIN' },
                            { value: 'ssnItin', label: 'SSN/ITIN' },
                            { value: 'foreign', label: 'Foreign' },
                        ]}
                    />

                    {/* text input field for taxIdNumber */}
                    <TextInputField
                        fieldName={"boiReport.taxIdNumber"}
                        fieldLabel={`g. Tax Identification number`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    {/* text input for taxIdCountry */}
                    {values.boiReport.taxIdType === 'foreign' && (
                        <TextInputField
                            fieldName={"boiReport.taxIdCountry"}
                            fieldLabel={`h. Country/Jurisdiction (if foreign tax ID only)`}
                            errors={errors}
                            touched={touched}
                            required={true}
                        />
                    )}

                </>
            )}

            {/* date prepared */}
            <TextInputField
                fieldName={"boiReport.datePrepared"}
                fieldLabel={`2. Date prepared (auto-filled)`}
                fieldType={"date"}
                errors={errors}
                touched={touched}
                required={true}
                placeholder={"MM/DD/YYYY"}
                disabled={true}
            />

            {/* if not newly exempt entity selected */}
            {values.boiReport && values.boiReport.typeOfFiling && values.boiReport.typeOfFiling !== 'newly exempt entity' && (
                <>
                    <h3>
                        Part I. Reporting Company Information
                    </h3>

                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Item 3 - Request to receive FinCEN Identifier (FinCEN ID):
                        </b>
                        <br />
                        Check this box to receive a unique FinCEN Identifier for the reporting company. The FinCEN Identifier will be provided in the submission confirmation details provided to the filer after the BOIR is accepted.
                        <br /><br />
                        <b>
                            Instructions for Item 4 - Foreign pooled investment vehicle:
                        </b>
                        <br />
                        Check this box if the reporting company is a foreign pooled investment vehicle required to report information pursuant to 31 CFR 1010.380(b)(2)(iii).
                        <br />
                        <ul>
                            <li>
                                If the reporting company is a foreign pooled investment vehicle, the company need only report one beneficial owner who exercises substantial control over the entity. If more than one individual exercise substantial control over the entity, the entity shall report information with respect to the individual who has the greatest authority over the strategic management of the entity. The report should not include any information about company applicants.
                            </li>
                        </ul>

                    </Collapsible>

                    <CheckboxInputField
                        fieldName={"boiReport.reportingCompany.requestFinCenId"}
                        fieldLabel={`3. Request to receive FinCEN ID`}
                        errors={errors}
                        touched={touched}
                    />

                    <CheckboxInputField
                        fieldName={"boiReport.reportingCompany.foreignPooledInvestment"}
                        fieldLabel={`4. Foreign pooled investment vehicle`}
                        errors={errors}
                        touched={touched}
                    />

                    <h4>
                        Legal name and alternate name(s)
                    </h4>

                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Item 5 - Reporting company legal name:
                        </b>
                        <br />
                        Enter the reporting company's full legal name as recorded on the articles of incorporation or other documents creating or registering the entity.
                        <br /><br />
                        <b>
                            Instructions for Item 6 - Alternate name:
                        </b>
                        <br />
                        Enter any of the reporting company's trade names, “doing business as” or DBA names, or “trading as” or T/A names. If the reporting company has multiple alternate names, use the “+” or “-“ buttons in the BOIR to add additional alternate name fields (one field for each alternate name). Do not include the acronyms DBA or AKA with the alternate name.

                    </Collapsible>

                    {/* reportingCompanyLegalName */}
                    <TextInputField
                        fieldName={"boiReport.reportingCompany.legalName"}
                        fieldLabel={`5. Reporting Company legal name`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    {/* alternateNames */}
                    <ArrayFieldInput
                        fieldName={"boiReport.reportingCompany.alternateNames"}
                        values={values.boiReport.reportingCompany.alternateNames}
                        blankEntryFn={() => ({ alternameName: "" })}
                        fields={[
                            {
                                fieldType: "text",
                                fieldName: "alternameName",
                                fieldLabel: "6. Alternate Name",
                                placeholder: "Enter alternate name",
                                required: true,
                            },
                        ]}
                        touched={touched}
                        errors={errors}
                        removeText={"- Click to remove alternate name"}
                        addText={"+ Click to add alternate name"}
                    />

                    <h4>Form of identification</h4>
                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Item 7 - Tax identification type:
                        </b>
                        <br />
                        Select “EIN” if the reporting company has a U.S. Employer Identification Number (EIN). Select “SSN-ITIN” if the reporting company utilizes a U.S. Social Security Number (SSN) or Individual Taxpayer Identification Number (ITIN) as a tax identification number. Select “Foreign” if the reporting company has a tax identification number issued by a foreign jurisdiction and does not have a U.S. tax identification number.
                        <br /><br />
                        <b>
                            Instructions for Item 8 - Tax identification number:
                        </b>
                        <br />
                        Enter the tax identification number for the reporting company.
                        <br /><br />
                        <b>
                            Instructions for Item 9 - Country/Jurisdiction:
                        </b>
                        <br />
                        If the tax identification type in item 7 is “Foreign”, select the foreign country/jurisdiction that issued the foreign tax identification number.
                    </Collapsible>

                    {/* reportingCompany taxIdType, taxIdNumber, taxIdCountry */}
                    <SelectInputField
                        fieldName={"boiReport.reportingCompany.taxIdType"}
                        fieldLabel={`7. Tax identification type`}
                        errors={errors}
                        touched={touched}
                        required={true}
                        placeholder={"- Select an ID Type -"}
                        options={[
                            { value: 'ein', label: 'EIN' },
                            { value: 'ssnItin', label: 'SSN/ITIN' },
                            { value: 'foreign', label: 'Foreign' },
                        ]}
                    />

                    <TextInputField
                        fieldName={"boiReport.reportingCompany.taxIdNumber"}
                        fieldLabel={`8. Tax identification number`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    {values.boiReport.reportingCompany.taxIdType === 'foreign' && (
                        <TextInputField
                            fieldName={"boiReport.reportingCompany.taxIdCountry"}
                            fieldLabel={`9. Country/Jurisdiction (if foreign tax ID only)`}
                            errors={errors}
                            touched={touched}
                            required={true}
                        />
                    )}

                    <h4>
                        Jurisdiction of formation or first registration
                    </h4>

                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Item 10 - Jurisdiction of formation or first registration:
                        </b>
                        <br />
                        Enter the country/jurisdiction of formation in item 10a. If United States is selected in 10a, then items 10b-10d should be completed, identifying the State or Tribal jurisdiction of formation. If a U.S. Territory is selected in 10a, then item 10b is automatically populated with the relevant U.S. Territory, and items 10c and 10d are unavailable. If Item 10a is a foreign country, then item 10e - 10f should be completed, identifying the State/U.S. Territory or Tribal jurisdiction in which the foreign reporting company first registered to do business in the United States. If the Tribal jurisdiction of formation (10c) or Tribal jurisdiction of first registration (10f) is not listed in the drop-down, select “Other” and enter the name of the Tribe in 10d or 10g.
                    </Collapsible>

                    {/* text input countryJurisdictionOfFormation */}
                    <TextInputField
                        fieldName={"boiReport.reportingCompany.countryJurisdictionOfFormation"}
                        fieldLabel={`10. a. Country/Jurisdiction of formation`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    <h4>
                        Current U.S. address
                    </h4>

                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Items 11 - 15 - Reporting company address:
                        </b>
                        <br />
                        Enter the reporting company's complete current street address information, including city, State, ZIP Code, and select in item 13 whether the address is in the United States or a specific U.S. Territory. If the reporting company has a principal place of business in the United States or U.S. Territory, the street address should be that of the principal place of business. Otherwise, the street address should be the primary location in the United States or U.S. Territory where the reporting company conducts business. If a U.S. Territory is selected in item 13, then item 14 is automatically populated with the relevant U.S. Territory.
                    </Collapsible>

                    {/* reporting company address portion */}
                    <TextInputField
                        fieldName={"boiReport.reportingCompany.address"}
                        fieldLabel={`11. Address`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    <TextInputField
                        fieldName={"boiReport.reportingCompany.city"}
                        fieldLabel={`12. City`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    <TextInputField
                        fieldName={"boiReport.reportingCompany.country"}
                        fieldLabel={`13. U.S. or U.S. Territory`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    <TextInputField
                        fieldName={"boiReport.reportingCompany.state"}
                        fieldLabel={`14. State`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    <TextInputField
                        fieldName={"boiReport.reportingCompany.zip"}
                        fieldLabel={`15. ZIP code`}
                        errors={errors}
                        touched={touched}
                        required={true}
                    />

                    {/* existingReportingCompany checkbox */}
                    <Collapsible
                        title="Need Help? (click to read more)"
                    >
                        <b>
                            Instructions for Item 16 – Existing reporting company:
                        </b>
                        <br />
                        Check this box if the reporting company was created or registered before January 1, 2024. Do not check the box if the reporting company was created or registered on or after January 1, 2024. Reporting companies that check this box are not required to report any company applicants; proceed to Part III.
                    </Collapsible>
                    <CheckboxInputField
                        fieldName={"boiReport.reportingCompany.existingReportingCompany"}
                        fieldLabel={`16. Existing reporting company (check if existing reporting company as of January 1, 2024)`}
                        errors={errors}
                        touched={touched}
                    />

                </>
            )} {/* end of part 1  */}


            {/* part 2 */}
            {
                values.boiReport &&
                values.boiReport.typeOfFiling &&
                values.boiReport.typeOfFiling !== 'newly exempt entity' &&
                values.boiReport.reportingCompany &&
                !values.boiReport.reportingCompany.existingReportingCompany && (
                    <>
                        <h3>
                            Part II. Company Applicant Information
                        </h3>
                    </>
                )} {/* end of part 2  */}


        </>
    )

}